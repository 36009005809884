<template>
  <section id="check">
    <div class="checkout_wrap pd_b50" style="margin-top: 40px">
      <div class="tabmenu tabmenu2">
        <ul>
          <li>
            <a class="active">{{ $t("main.usageHistory") }}</a>
          </li>
          <li @click="onClickGoPayListPage">
            <a>{{ $t("main.paymentHistory") }}</a>
          </li>
        </ul>
      </div>
      <div class="pay_list_wrap">
        <div class="pay_title">
          {{ $t("main.myUsageHistory") }}
          <!-- <a class="btn_more" @click="onClickGoPayMyPage" /> -->
        </div>
        <dl class="pay_list bg">
          <dt>{{ $t("main.selfUsageHistory", [payerName]) }}</dt>
          <dd>{{ $t("common.won", [numberWithCommas(totAmt)]) }}</dd>
        </dl>
        <dl class="pay_list">
          <dt>{{ $t("main.feeAmount") }}</dt>
          <dd class="c_r">
            {{
              $t("common.won", [numberWithCommas(takeAmtByClassCode("FEE"))])
            }}
          </dd>
          <dt>{{ $t("main.cartAmount") }}</dt>
          <dd>
            {{
              $t("common.won", [numberWithCommas(takeAmtByClassCode("CART"))])
            }}
          </dd>
        </dl>
        <hr />
        <dl class="pay_list">
          <dt>{{ $t("main.paymentAmount") }}</dt>
          <dd class="c_r">
            {{ $t("common.won", [numberWithCommas(paidAmt)]) }}
          </dd>
          <dt>{{ $t("main.remainingPaymentAmount") }}</dt>
          <dd>{{ $t("common.won", [numberWithCommas(remainAmt)]) }}</dd>
        </dl>
      </div>
      <div class="pay_wrap">
        <p class="title">{{ $t("main.paymentMethod") }}</p>
        <dl>
          <!-- <dt>
            <input name="pay_list" class="radio_basic" type="radio" />
            {{ $t("main.generalPayment") }}
          </dt> -->
          <dd>
            <ul class="pay_select">
              <!-- <li>
                <input
                  name="pay_select"
                  class="radio_pay"
                  type="radio"
                  disabled
                />
                <label>{{ $t("main.samsungPay") }}</label>
              </li> -->
              <li>
                <input
                  name="pay_select"
                  class="radio_pay"
                  type="radio"
                  checked
                />
                <label>{{ $t("main.creditCardPayment") }}</label>
              </li>
            </ul>
          </dd>
        </dl>
      </div>
      <div class="total">
        {{ $t("main.amountToBePaid") }}
        <strong>{{ $t("common.won", [numberWithCommas(remainAmt)]) }}</strong>
      </div>
      <div class="btn_pay_wrap btn_pay_fixed">
        <a class="btn_point" @click="onClickPay">{{ $t("common.pay") }}</a>
      </div>
    </div>
  </section>
</template>

<script>
import checkOutMixin from "@/mixins/checkOutMixin";

export default {
  name: "Pay",
  mixins: [checkOutMixin],
  async created() {
    await this.fetch("pay");
  },
  methods: {
    async onClickPay() {
      if (this.remainAmt > 0) {
        // TODO : 결제
      } else {
        await this.confirm({ content: this.$t("payMessage.noAmountToPay") });
      }
    },
  },
};
</script>
